import React from 'react';
import { StaticQuery, graphql, navigate  } from 'gatsby';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const NotFoundPage: React.FC<{}> = () => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${page404StaticQuery}`}
		render={(data) => {
			const url = typeof window !== 'undefined' ? window.location.href : null;
			if(url){
				let found = false;
				const urlArr = url.split('-');
				const possibleItemCode = urlArr[urlArr.length-1];
				
				data.allErpItem.nodes.forEach((value) => {
					if (!found && value.item_code?.toLowerCase() === possibleItemCode.toLowerCase()) {
						found = true;
						value.path && navigate(value.path);
						return
					}
				})
			}

			return (
				<PrimaryLayout>
					<SEO title="404: Not found" />
					<Container py="40px">
						<h1>Página no encontrada :(</h1>
					</Container>
				</PrimaryLayout>
			)
		}}
	/>
);

const page404StaticQuery = graphql`
	query {
		allErpItem {
			nodes {
				item_code
				path
			}
		}
	}
`;

export default NotFoundPage;
